'use client';

import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/theme-utils';
import React from 'react';

import type { ComponentStyleConfig } from '@chakra-ui/theme';

const Popover: ComponentStyleConfig = {
  parts: ['popper'],
  baseStyle: {
    popper: {},
  },
  variants: {
    fullWidth: {
      popper: {
        minWidth: '100% !important',
      },
    },
    mobileWidth: {
      popper: {
        position: 'static !important',
        minWidth: 'auto !important',
      },
    },
  },
};

//for remove pointer event overlay while close the drawer
const Drawer: ComponentStyleConfig = {
  variants: {
    clickThrough: {
      overlay: {
        pointerEvents: 'none',
        background: 'transparent',
      },
      dialogContainer: {
        pointerEvents: 'none',
        background: 'transparent',
      },
      dialog: {
        pointerEvents: 'auto',
      },
    },
  },
};

const theme = extendTheme({
  components: {
    Popover,
    Drawer,
  },
});

export default function Component({ children }: { children: React.ReactNode }) {
  return (
    <ChakraProvider
      theme={theme}
      resetCSS={true}
      toastOptions={{ defaultOptions: { duration: 9000, isClosable: true } }}
    >
      {children}
    </ChakraProvider>
  );
}
