export const DEALERSHIP_REQUEST_FIELDS = [
  'id',
  'name',
  'main_image',
  'address_1',
  'address_2',
  'code',
  'main_number',
  'google_address',
  'city.name',
  'operating_hours.operating_hours_id.id',
  'operating_hours.operating_hours_id.day_of_the_week',
  'operating_hours.operating_hours_id.opening_time',
  'operating_hours.operating_hours_id.closing_time',
  'enquiry_form_services',
];

export const FILTER_BY_DEALERSHIP = (id?: string | null) => {
  if (!id) return null;

  return {
    dealership_id: {
      _eq: id,
    },
  };
};

export const SEO_DEALER_DATA = {
  SUFFIX: 'Kia Dealership South Africa ',
};

export const SEO_DEALERSHIPS_DATA = {
  DESCRIPTION:
    'Visit our website for the location, contact details and opening hours for KIA Retail in South Africa. Find out more about how you can get in touch with us, as well as how to book your next test drive in a pre owned vehicle, quickly and easily.',
};

export const ENQUIRY_FORM_FOR_NEW_CARS = 'New Cars';

export const ENQUIRY_FORM_FOR_SERVICE = 'Service';

export const ENQUIRY_FORM_FOR_PARTS = 'Parts';

export const kiaRoadSideAssistPhoneNumber = '0861542000';

export const COOKIE_NAME_DEALERSHIP_ID = 'kia_dealership_id';
